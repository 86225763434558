import { useRoutes } from "react-router-dom";
import { MainAppRoutes } from "./MainAppRoutes";
import useStyles from "./style-jss";
import MainLayout from "layout/MainLayout";
import { TermAndCondition } from "./PageAsync";

// routes
// import MainRoutes from "./MainAppRoutes";

export function AppRoute() {
  const classes = useStyles() as any;
  let element = MainAppRoutes({ classes });
  return useRoutes([
    {
      path: "",
      element: <MainLayout />,
    },
    element,
    // {
    // path: "",
    // element: <MainLayout />,
    // },
    // {
    //   path: "fr",
    //   element: <MainLayout />,
    // },
    {
      path: "/terms-and-conditions",
      element: <TermAndCondition />,
    },
  ]);
}
export default AppRoute;
