import MainLayout from "layout/MainLayout";
import { Sauce, TermAndCondition } from "./PageAsync";
import PropTypes, { element } from "prop-types";
// ==============================|| MAIN ROUTING ||============================== //

// export default MainRoutes;

export function MainAppRoutes({ classes }: any) {
  const MainRoutes = {
    path: "fr",
    // element: <MainLayout />,
    children: [
      {
        path: "",
        element: <MainLayout />,
      },
      {
        path: "terms-and-conditions",
        element: <TermAndCondition />,
      },


      // categories start
    ],
  };
  return MainRoutes;
}

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};
