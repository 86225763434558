import { createSlice } from "@reduxjs/toolkit";
import { getDataFromLocalStorage, getStringFromLocalStorage } from "utils/localStore";
const token = getStringFromLocalStorage("token");
const user = getDataFromLocalStorage("user");

export interface language_state {
  language: string;
}

const initialState: language_state = {
//   language: token && user?.language ? user?.language : "en"
  language: "en"
};

export const language_slicer = createSlice({
  name: "language",
  initialState,
  reducers: {
    language_ge: (state: language_state) => {
        return {
            language: "ge"
        }
    },
    language_en: (state: language_state) => {
        return {
            language: "en"
        }
    }
  },
});

export const { language_ge, language_en } = language_slicer.actions;

export default language_slicer.reducer;
