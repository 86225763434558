import { lazy } from "react";
import Loadable from "ui-components/Loadable";

export const Home = Loadable(lazy(() => import("views/Home")));

export const Sauce = Loadable(lazy(() => import("views/sauce")));

export const Stores = Loadable(lazy(() => import("views/stores")));
export const Cart = Loadable(lazy(() => import("views/cart")));
export const Login = Loadable(lazy(() => import("views/login")));
export const TermAndCondition = Loadable(
  lazy(() => import("views/Terms_and_Condtions"))
);
export const deliveryPolicy = Loadable(
  lazy(() => import("views/delivery_policy"))
);
