export const customFlagList = [
    // note: this is updated list of 243 countries and territories, some names are shortened, some irrelevant entries are revomed.
    { name: 'a', title: true, code: 'a', dial_code: '-1' },
    {
        name: 'Afghanistan',
        flag: require('../assets/images/country-flags/Afghanistan.png'),
        code: 'AF',
        dial_code: '+93',
    },
    {
        name: 'Åland Islands',
        flag: require('../assets/images/country-flags/Aland.png'),
        code: 'AX',
        dial_code: '+358',
    },
    {
        name: 'Albania',
        flag: require('../assets/images/country-flags/Albania.png'),
        code: 'AL',
        dial_code: '+355',
    },
    {
        name: 'Algeria',
        flag: require('../assets/images/country-flags/Algeria.png'),
        code: 'DZ',
        dial_code: '+213',
    },
    {
        name: 'American Samoa',
        flag: require('../assets/images/country-flags/American-Samoa.png'),
        code: 'AS',
        dial_code: '+1684',
    },
    {
        name: 'Andorra',
        flag: require('../assets/images/country-flags/Andorra.png'),
        code: 'AD',
        dial_code: '+376',
    },
    {
        name: 'Angola',
        flag: require('../assets/images/country-flags/Angola.png'),
        code: 'AO',
        dial_code: '+244',
    },
    {
        name: 'Anguilla',
        flag: require('../assets/images/country-flags/Anguilla.png'),
        code: 'AI',
        dial_code: '+1264',
    },
    {
        name: 'Antarctica',
        flag: require('../assets/images/country-flags/Antarctica.png'),
        code: 'AQ',
        dial_code: '+672',
    },
    {
        name: 'Antigua & Barbuda',
        flag: require('../assets/images/country-flags/Antigua-and-Barbuda.png'),
        code: 'AG',
        dial_code: '+1268',
    },
    {
        name: 'Argentina',
        flag: require('../assets/images/country-flags/Argentina.png'),
        code: 'AR',
        dial_code: '+54',
    },
    // {
    //   name: "Armenia",
    //   flag: require("../assets/images/country-flags/Armenia.png"),
    //   code: "AM",
    //   dial_code: "+374",
    // },
    {
        name: 'Aruba',
        flag: require('../assets/images/country-flags/Aruba.png'),
        code: 'AW',
        dial_code: '+297',
    },
    {
        name: 'Australia',
        flag: require('../assets/images/country-flags/Australia.png'),
        code: 'AU',
        dial_code: '+61',
    },
    {
        name: 'Austria',
        flag: require('../assets/images/country-flags/Austria.png'),
        code: 'AT',
        dial_code: '+43',
    },
    {
        name: 'Azerbaijan',
        flag: require('../assets/images/country-flags/Azerbaijan.png'),
        code: 'AZ',
        dial_code: '+994',
    },

    { name: 'b', title: true, code: 'b', dial_code: '-1' },
    {
        name: 'Bahamas',
        flag: require('../assets/images/country-flags/Bahamas.png'),
        code: 'BS',
        dial_code: '+1242',
    },
    {
        name: 'Bahrain',
        flag: require('../assets/images/country-flags/Bahrain.png'),
        code: 'BH',
        dial_code: '+973',
    },
    {
        name: 'Bangladesh',
        flag: require('../assets/images/country-flags/Bangladesh.png'),
        code: 'BD',
        dial_code: '+880',
    },
    {
        name: 'Barbados',
        flag: require('../assets/images/country-flags/Barbados.png'),
        code: 'BB',
        dial_code: '+1246',
    },
    {
        name: 'Belarus',
        flag: require('../assets/images/country-flags/Belarus.png'),
        code: 'BY',
        dial_code: '+375',
    },
    {
        name: 'Belgium',
        flag: require('../assets/images/country-flags/Belgium.png'),
        code: 'BE',
        dial_code: '+32',
    },
    {
        name: 'Belize',
        flag: require('../assets/images/country-flags/Belize.png'),
        code: 'BZ',
        dial_code: '+501',
    },
    {
        name: 'Benin',
        flag: require('../assets/images/country-flags/Benin.png'),
        code: 'BJ',
        dial_code: '+229',
    },
    {
        name: 'Bermuda',
        flag: require('../assets/images/country-flags/Bermuda.png'),
        code: 'BM',
        dial_code: '+1441',
    },
    {
        name: 'Bhutan',
        flag: require('../assets/images/country-flags/Bhutan.png'),
        code: 'BT',
        dial_code: '+975',
    },
    {
        name: 'Bolivia',
        flag: require('../assets/images/country-flags/Bolivia.png'),
        code: 'BO',
        dial_code: '+591',
    },
    {
        name: 'Bosnia Herzegovina',
        flag: require('../assets/images/country-flags/Bosnia-and-Herzegovina.png'),
        code: 'BA',
        dial_code: '+387',
    },
    {
        name: 'Botswana',
        flag: require('../assets/images/country-flags/Botswana.png'),
        code: 'BW',
        dial_code: '+267',
    },
    {
        name: 'Bouvet Island',
        flag: require('../assets/images/country-flags/Norway.png'),
        code: 'BV',
        dial_code: '+47',
    },
    {
        name: 'Brazil',
        flag: require('../assets/images/country-flags/Brazil.png'),
        code: 'BR',
        dial_code: '+55',
    },
    {
        name: 'Brunei',
        flag: require('../assets/images/country-flags/Brunei.png'),
        code: 'BN',
        dial_code: '+673',
    },
    {
        name: 'Bulgaria',
        flag: require('../assets/images/country-flags/Bulgaria.png'),
        code: 'BG',
        dial_code: '+359',
    },
    {
        name: 'Burkina Faso',
        flag: require('../assets/images/country-flags/Burkina-Faso.png'),
        code: 'BF',
        dial_code: '+226',
    },
    {
        name: 'Burundi',
        flag: require('../assets/images/country-flags/Burundi.png'),
        code: 'BI',
        dial_code: '+257',
    },

    { name: 'c', title: true, code: 'c', dial_code: '-1' },
    {
        name: 'Cambodia',
        flag: require('../assets/images/country-flags/Cambodia.png'),
        code: 'KH',
        dial_code: '+855',
    },
    {
        name: 'Cameroon',
        flag: require('../assets/images/country-flags/Cameroon.png'),
        code: 'CM',
        dial_code: '+237',
    },
    {
        name: 'Canada',
        flag: require('../assets/images/country-flags/Canada.png'),
        code: 'CA',
        dial_code: '+1',
    },
    {
        name: 'Cape Verde',
        flag: require('../assets/images/country-flags/Cape-Verde.png'),
        code: 'CV',
        dial_code: '+238',
    },
    {
        name: 'Cayman Islands',
        flag: require('../assets/images/country-flags/Cayman-Islands.png'),
        code: 'KY',
        dial_code: '+345',
    },
    {
        name: 'Central African Republic',
        flag: require('../assets/images/country-flags/Central-African-Republic.png'),
        code: 'CF',
        dial_code: '+236',
    },
    {
        name: 'Chad',
        flag: require('../assets/images/country-flags/Chad.png'),
        code: 'TD',
        dial_code: '+235',
    },
    {
        name: 'Chile',
        flag: require('../assets/images/country-flags/Chile.png'),
        code: 'CL',
        dial_code: '+56',
    },
    {
        name: 'China',
        flag: require('../assets/images/country-flags/China.png'),
        code: 'CN',
        dial_code: '+86',
    },
    {
        name: 'Christmas Island',
        flag: require('../assets/images/country-flags/Christmas-Island.png'),
        code: 'CX',
        dial_code: '+61',
    },
    {
        name: 'Cocos (Keeling) Islands',
        flag: require('../assets/images/country-flags/Cocos-Keeling-Islands.png'),
        code: 'CC',
        dial_code: '+61',
    },
    {
        name: 'Colombia',
        flag: require('../assets/images/country-flags/Colombia.png'),
        code: 'CO',
        dial_code: '+57',
    },
    {
        name: 'Comoros',
        flag: require('../assets/images/country-flags/Comoros.png'),
        code: 'KM',
        dial_code: '+269',
    },
    {
        name: 'Congo, DRC',
        flag: require('../assets/images/country-flags/Democratic-Republic-of-the-Congo.png'),
        code: 'CD',
        dial_code: '+243',
    },
    {
        name: 'Congo, Republic',
        flag: require('../assets/images/country-flags/Congo-Republic.png'),
        code: 'CG',
        dial_code: '+242',
    },
    {
        name: 'Cook Islands',
        flag: require('../assets/images/country-flags/Cook-Islands.png'),
        code: 'CK',
        dial_code: '+682',
    },
    {
        name: 'Costa Rica',
        flag: require('../assets/images/country-flags/Costa-Rica.png'),
        code: 'CR',
        dial_code: '+506',
    },
    {
        name: 'Croatia',
        flag: require('../assets/images/country-flags/Croatia.png'),
        code: 'HR',
        dial_code: '+385',
    },
    {
        name: 'Cuba',
        flag: require('../assets/images/country-flags/Cuba.png'),
        code: 'CU',
        dial_code: '+53',
    },
    {
        name: 'Cyprus',
        flag: require('../assets/images/country-flags/Cyprus.png'),
        code: 'CY',
        dial_code: '+357',
    },
    {
        name: 'Czech Republic',
        flag: require('../assets/images/country-flags/Czech-Republic.png'),
        code: 'CZ',
        dial_code: '+420',
    },

    { name: 'd', title: true, code: 'd', dial_code: '-1' },
    {
        name: 'Denmark',
        flag: require('../assets/images/country-flags/Denmark.png'),
        code: 'DK',
        dial_code: '+45',
    },
    {
        name: 'Djibouti',
        flag: require('../assets/images/country-flags/Djibouti.png'),
        code: 'DJ',
        dial_code: '+253',
    },
    {
        name: 'Dominica',
        flag: require('../assets/images/country-flags/Dominica.png'),
        code: 'DM',
        dial_code: '+1767',
    },
    {
        name: 'Dominican Republic',
        flag: require('../assets/images/country-flags/Dominican-Republic.png'),
        code: 'DO',
        dial_code: '+1849',
    },

    { name: 'e', title: true, code: 'e', dial_code: '-1' },
    {
        name: 'Ecuador',
        flag: require('../assets/images/country-flags/Ecuador.png'),
        code: 'EC',
        dial_code: '+593',
    },
    {
        name: 'Egypt',
        flag: require('../assets/images/country-flags/Egypt.png'),
        code: 'EG',
        dial_code: '+20',
    },
    {
        name: 'El Salvador',
        flag: require('../assets/images/country-flags/El-Salvador.png'),
        code: 'SV',
        dial_code: '+503',
    },
    {
        name: 'Equatorial Guinea',
        flag: require('../assets/images/country-flags/Equatorial-Guinea.png'),
        code: 'GQ',
        dial_code: '+240',
    },
    {
        name: 'Eritrea',
        flag: require('../assets/images/country-flags/Eritrea.png'),
        code: 'ER',
        dial_code: '+291',
    },
    {
        name: 'Estonia',
        flag: require('../assets/images/country-flags/Estonia.png'),
        code: 'EE',
        dial_code: '+372',
    },
    {
        name: 'Ethiopia',
        flag: require('../assets/images/country-flags/Ethiopia.png'),
        code: 'ET',
        dial_code: '+251',
    },

    { name: 'f', title: true, code: 'f', dial_code: '-1' },
    {
        name: 'Falkland Islands',
        flag: require('../assets/images/country-flags/Falkland-Islands.png'),
        code: 'FK',
        dial_code: '+500',
    },
    {
        name: 'Faroe Islands',
        flag: require('../assets/images/country-flags/Faroes.png'),
        code: 'FO',
        dial_code: '+298',
    },
    {
        name: 'Fiji',
        flag: require('../assets/images/country-flags/Fiji.png'),
        code: 'FJ',
        dial_code: '+679',
    },
    {
        name: 'Finland',
        flag: require('../assets/images/country-flags/Finland.png'),
        code: 'FI',
        dial_code: '+358',
    },
    {
        name: 'France',
        flag: require('../assets/images/country-flags/France.png'),
        code: 'FR',
        dial_code: '+33',
    },
    {
        name: 'French Guiana',
        flag: require('../assets/images/country-flags/French-Southern-Territories.png'),
        code: 'GF',
        dial_code: '+594',
    },
    {
        name: 'French Polynesia',
        flag: require('../assets/images/country-flags/French-Polynesia.png'),
        code: 'PF',
        dial_code: '+689',
    },

    { name: 'g', title: true, code: 'g', dial_code: '-1' },
    {
        name: 'Gabon',
        flag: require('../assets/images/country-flags/Gabon.png'),
        code: 'GA',
        dial_code: '+241',
    },
    {
        name: 'Gambia',
        flag: require('../assets/images/country-flags/Gambia.png'),
        code: 'GM',
        dial_code: '+220',
    },
    {
        name: 'Georgia',
        flag: require('../assets/images/country-flags/Georgia.png'),
        code: 'GE',
        dial_code: '+995',
    },
    {
        name: 'Germany',
        flag: require('../assets/images/country-flags/Germany.png'),
        code: 'DE',
        dial_code: '+49',
    },
    {
        name: 'Ghana',
        flag: require('../assets/images/country-flags/Ghana.png'),
        code: 'GH',
        dial_code: '+233',
    },
    {
        name: 'Gibraltar',
        flag: require('../assets/images/country-flags/Gibraltar.png'),
        code: 'GI',
        dial_code: '+350',
    },
    {
        name: 'Greece',
        flag: require('../assets/images/country-flags/Greece.png'),
        code: 'GR',
        dial_code: '+30',
    },
    {
        name: 'Greenland',
        flag: require('../assets/images/country-flags/Greenland.png'),
        code: 'GL',
        dial_code: '+299',
    },
    {
        name: 'Grenada',
        flag: require('../assets/images/country-flags/Grenada.png'),
        code: 'GD',
        dial_code: '+1473',
    },
    {
        name: 'Guadeloupe',
        flag: require('../assets/images/country-flags/France.png'),
        code: 'GP',
        dial_code: '+590',
    },
    {
        name: 'Guam',
        flag: require('../assets/images/country-flags/Guam.png'),
        code: 'GU',
        dial_code: '+1671',
    },
    {
        name: 'Guatemala',
        flag: require('../assets/images/country-flags/Guatemala.png'),
        code: 'GT',
        dial_code: '+502',
    },
    {
        name: 'Guernsey',
        flag: require('../assets/images/country-flags/Guernsey.png'),
        code: 'GG',
        dial_code: '+44',
    },
    {
        name: 'Guinea',
        flag: require('../assets/images/country-flags/Guinea.png'),
        code: 'GN',
        dial_code: '+224',
    },
    {
        name: 'Guinea-Bissau',
        flag: require('../assets/images/country-flags/Guinea-Bissau.png'),
        code: 'GW',
        dial_code: '+245',
    },
    {
        name: 'Guyana',
        flag: require('../assets/images/country-flags/Guyana.png'),
        code: 'GY',
        dial_code: '+592',
    },

    { name: 'h', title: true, code: 'h', dial_code: '-1' },
    {
        name: 'Haiti',
        flag: require('../assets/images/country-flags/Haiti.png'),
        code: 'HT',
        dial_code: '+509',
    },
    {
        name: 'Vatican City',
        flag: require('../assets/images/country-flags/Vatican-City.png'),
        code: 'VA',
        dial_code: '+379',
    },
    {
        name: 'Honduras',
        flag: require('../assets/images/country-flags/Honduras.png'),
        code: 'HN',
        dial_code: '+504',
    },
    {
        name: 'Hong Kong',
        flag: require('../assets/images/country-flags/Hong-Kong.png'),
        code: 'HK',
        dial_code: '+852',
    },
    {
        name: 'Hungary',
        flag: require('../assets/images/country-flags/Hungary.png'),
        code: 'HU',
        dial_code: '+36',
    },

    { name: 'i', title: true, code: 'i', dial_code: '-1' },
    {
        name: 'Iceland',
        flag: require('../assets/images/country-flags/Iceland.png'),
        code: 'IS',
        dial_code: '+354',
    },
    {
        name: 'India',
        flag: require('../assets/images/country-flags/India.png'),
        code: 'IN',
        dial_code: '+91',
    },
    {
        name: 'Indonesia',
        flag: require('../assets/images/country-flags/Indonesia.png'),
        code: 'ID',
        dial_code: '+62',
    },
    {
        name: 'Iran',
        flag: require('../assets/images/country-flags/Iran.png'),
        code: 'IR',
        dial_code: '+98',
    },
    {
        name: 'Iraq',
        flag: require('../assets/images/country-flags/Iraq.png'),
        code: 'IQ',
        dial_code: '+964',
    },
    {
        name: 'Ireland',
        flag: require('../assets/images/country-flags/Ireland.png'),
        code: 'IE',
        dial_code: '+353',
    },
    {
        name: 'Isle of Man',
        flag: require('../assets/images/country-flags/Isle-of-Man.png'),
        code: 'IM',
        dial_code: '+44',
    },
    {
        name: 'Israel',
        flag: require('../assets/images/country-flags/Israel.png'),
        code: 'IL',
        dial_code: '+972',
    },
    {
        name: 'Italy',
        flag: require('../assets/images/country-flags/Italy.png'),
        code: 'IT',
        dial_code: '+39',
    },
    {
        name: 'Ivory Coast',
        flag: require('../assets/images/country-flags/Cote-dIvoire.png'),
        code: 'CI',
        dial_code: '+225',
    },

    { name: 'j', title: true, code: 'j', dial_code: '-1' },
    {
        name: 'Jamaica',
        flag: require('../assets/images/country-flags/Jamaica.png'),
        code: 'JM',
        dial_code: '+1876',
    },
    {
        name: 'Japan',
        flag: require('../assets/images/country-flags/Japan.png'),
        code: 'JP',
        dial_code: '+81',
    },
    {
        name: 'Jersey',
        flag: require('../assets/images/country-flags/Jersey.png'),
        code: 'JE',
        dial_code: '+44',
    },
    {
        name: 'Jordan',
        flag: require('../assets/images/country-flags/Jordan.png'),
        code: 'JO',
        dial_code: '+962',
    },

    { name: 'k', title: true, code: 'k', dial_code: '-1' },
    {
        name: 'Kazakhstan',
        flag: require('../assets/images/country-flags/Kazakhstan.png'),
        code: 'KZ',
        dial_code: '+7',
    },
    {
        name: 'Kenya',
        flag: require('../assets/images/country-flags/Kenya.png'),
        code: 'KE',
        dial_code: '+254',
    },
    {
        name: 'Kiribati',
        flag: require('../assets/images/country-flags/Kiribati.png'),
        code: 'KI',
        dial_code: '+686',
    },
    {
        name: 'Korea, North',
        flag: require('../assets/images/country-flags/North-Korea.png'),
        code: 'KP',
        dial_code: '+850',
    },
    {
        name: 'Korea, South',
        flag: require('../assets/images/country-flags/South-Korea.png'),
        code: 'KR',
        dial_code: '+82',
    },
    {
        name: 'Kosovo',
        flag: require('../assets/images/country-flags/Kosovo.png'),
        code: 'XK',
        dial_code: '+383',
    },
    {
        name: 'Kuwait',
        flag: require('../assets/images/country-flags/Kuwait.png'),
        code: 'KW',
        dial_code: '+965',
    },
    {
        name: 'Kyrgyzstan',
        flag: require('../assets/images/country-flags/Kyrgyzstan.png'),
        code: 'KG',
        dial_code: '+996',
    },

    { name: 'l', title: true, code: 'l', dial_code: '-1' },
    {
        name: 'Laos',
        flag: require('../assets/images/country-flags/Laos.png'),
        code: 'LA',
        dial_code: '+856',
    },
    {
        name: 'Latvia',
        flag: require('../assets/images/country-flags/Latvia.png'),
        code: 'LV',
        dial_code: '+371',
    },
    {
        name: 'Lebanon',
        flag: require('../assets/images/country-flags/Lebanon.png'),
        code: 'LB',
        dial_code: '+961',
    },
    {
        name: 'Lesotho',
        flag: require('../assets/images/country-flags/Lesotho.png'),
        code: 'LS',
        dial_code: '+266',
    },
    {
        name: 'Liberia',
        flag: require('../assets/images/country-flags/Liberia.png'),
        code: 'LR',
        dial_code: '+231',
    },
    {
        name: 'Libya',
        flag: require('../assets/images/country-flags/Libya.png'),
        code: 'LY',
        dial_code: '+218',
    },
    {
        name: 'Liechtenstein',
        flag: require('../assets/images/country-flags/Liechtenstein.png'),
        code: 'LI',
        dial_code: '+423',
    },
    {
        name: 'Lithuania',
        flag: require('../assets/images/country-flags/Lithuania.png'),
        code: 'LT',
        dial_code: '+370',
    },
    {
        name: 'Luxembourg',
        flag: require('../assets/images/country-flags/Luxembourg.png'),
        code: 'LU',
        dial_code: '+352',
    },

    { name: 'm', title: true, code: 'm', dial_code: '-1' },
    {
        name: 'Macao',
        flag: require('../assets/images/country-flags/Macau.png'),
        code: 'MO',
        dial_code: '+853',
    },
    {
        name: 'Macedonia',
        flag: require('../assets/images/country-flags/Macedonia.png'),
        code: 'MK',
        dial_code: '+389',
    },
    {
        name: 'Madagascar',
        flag: require('../assets/images/country-flags/Madagascar.png'),
        code: 'MG',
        dial_code: '+261',
    },
    {
        name: 'Malawi',
        flag: require('../assets/images/country-flags/Malawi.png'),
        code: 'MW',
        dial_code: '+265',
    },
    {
        name: 'Malaysia',
        flag: require('../assets/images/country-flags/Malaysia.png'),
        code: 'MY',
        dial_code: '+60',
    },
    {
        name: 'Maldives',
        flag: require('../assets/images/country-flags/Maldives.png'),
        code: 'MV',
        dial_code: '+960',
    },
    {
        name: 'Mali',
        flag: require('../assets/images/country-flags/Mali.png'),
        code: 'ML',
        dial_code: '+223',
    },
    {
        name: 'Malta',
        flag: require('../assets/images/country-flags/Malta.png'),
        code: 'MT',
        dial_code: '+356',
    },
    {
        name: 'Marshall Islands',
        flag: require('../assets/images/country-flags/Marshall-Islands.png'),
        code: 'MH',
        dial_code: '+692',
    },
    {
        name: 'Martinique',
        flag: require('../assets/images/country-flags/Martinique.png'),
        code: 'MQ',
        dial_code: '+596',
    },
    {
        name: 'Mauritania',
        flag: require('../assets/images/country-flags/Mauritania.png'),
        code: 'MR',
        dial_code: '+222',
    },
    {
        name: 'Mauritius',
        flag: require('../assets/images/country-flags/Mauritius.png'),
        code: 'MU',
        dial_code: '+230',
    },
    {
        name: 'Mayotte',
        flag: require('../assets/images/country-flags/Mayotte.png'),
        code: 'YT',
        dial_code: '+262',
    },
    {
        name: 'Mexico',
        flag: require('../assets/images/country-flags/Mexico.png'),
        code: 'MX',
        dial_code: '+52',
    },
    {
        name: 'Micronesia',
        flag: require('../assets/images/country-flags/Micronesia.png'),
        code: 'FM',
        dial_code: '+691',
    },
    {
        name: 'Moldova',
        flag: require('../assets/images/country-flags/Moldova.png'),
        code: 'MD',
        dial_code: '+373',
    },
    {
        name: 'Monaco',
        flag: require('../assets/images/country-flags/Monaco.png'),
        code: 'MC',
        dial_code: '+377',
    },
    {
        name: 'Mongolia',
        flag: require('../assets/images/country-flags/Mongolia.png'),
        code: 'MN',
        dial_code: '+976',
    },
    {
        name: 'Montenegro',
        flag: require('../assets/images/country-flags/Montenegro.png'),
        code: 'ME',
        dial_code: '+382',
    },
    {
        name: 'Montserrat',
        flag: require('../assets/images/country-flags/Montserrat.png'),
        code: 'MS',
        dial_code: '+1664',
    },
    {
        name: 'Morocco',
        flag: require('../assets/images/country-flags/Morocco.png'),
        code: 'MA',
        dial_code: '+212',
    },
    {
        name: 'Mozambique',
        flag: require('../assets/images/country-flags/Mozambique.png'),
        code: 'MZ',
        dial_code: '+258',
    },
    {
        name: 'Myanmar',
        flag: require('../assets/images/country-flags/Myanmar.png'),
        code: 'MM',
        dial_code: '+95',
    },

    { name: 'n', title: true, code: 'n', dial_code: '-1' },
    {
        name: 'Namibia',
        flag: require('../assets/images/country-flags/Namibia.png'),
        code: 'NA',
        dial_code: '+264',
    },
    {
        name: 'Nauru',
        flag: require('../assets/images/country-flags/Nauru.png'),
        code: 'NR',
        dial_code: '+674',
    },
    {
        name: 'Nepal',
        flag: require('../assets/images/country-flags/Nepal.png'),
        code: 'NP',
        dial_code: '+977',
    },
    {
        name: 'Netherlands',
        flag: require('../assets/images/country-flags/Netherlands.png'),
        code: 'NL',
        dial_code: '+31',
    },
    {
        name: 'Netherlands Antilles',
        flag: require('../assets/images/country-flags/Netherlands-Antilles.png'),
        code: 'AN',
        dial_code: '+599',
    },
    {
        name: 'New Caledonia',
        flag: require('../assets/images/country-flags/New-Caledonia.png'),
        code: 'NC',
        dial_code: '+687',
    },
    {
        name: 'New Zealand',
        flag: require('../assets/images/country-flags/New-Zealand.png'),
        code: 'NZ',
        dial_code: '+64',
    },
    {
        name: 'Nicaragua',
        flag: require('../assets/images/country-flags/Nicaragua.png'),
        code: 'NI',
        dial_code: '+505',
    },
    {
        name: 'Niger',
        flag: require('../assets/images/country-flags/Niger.png'),
        code: 'NE',
        dial_code: '+227',
    },
    {
        name: 'Nigeria',
        flag: require('../assets/images/country-flags/Nigeria.png'),
        code: 'NG',
        dial_code: '+234',
    },
    {
        name: 'Niue',
        flag: require('../assets/images/country-flags/Niue.png'),
        code: 'NU',
        dial_code: '+683',
    },
    {
        name: 'Norfolk Island',
        flag: require('../assets/images/country-flags/Norfolk-Island.png'),
        code: 'NF',
        dial_code: '+672',
    },
    {
        name: 'Northern Mariana',
        flag: require('../assets/images/country-flags/Northern-Mariana-Islands.png'),
        code: 'MP',
        dial_code: '+1670',
    },
    {
        name: 'Norway',
        flag: require('../assets/images/country-flags/Norway.png'),
        code: 'NO',
        dial_code: '+47',
    },

    { name: 'o', title: true, code: 'o', dial_code: '-1' },
    {
        name: 'Oman',
        flag: require('../assets/images/country-flags/Oman.png'),
        code: 'OM',
        dial_code: '+968',
    },

    { name: 'p', title: true, code: 'p', dial_code: '-1' },
    {
        name: 'Pakistan',
        flag: require('../assets/images/country-flags/Pakistan.png'),
        code: 'PK',
        dial_code: '+92',
    },
    {
        name: 'Palau',
        flag: require('../assets/images/country-flags/Palau.png'),
        code: 'PW',
        dial_code: '+680',
    },
    {
        name: 'Palestine',
        flag: require('../assets/images/country-flags/Palestine.png'),
        code: 'PS',
        dial_code: '+970',
    },
    {
        name: 'Panama',
        flag: require('../assets/images/country-flags/Panama.png'),
        code: 'PA',
        dial_code: '+507',
    },
    {
        name: 'Papua New Guinea',
        flag: require('../assets/images/country-flags/Papua-New-Guinea.png'),
        code: 'PG',
        dial_code: '+675',
    },
    {
        name: 'Paraguay',
        flag: require('../assets/images/country-flags/Paraguay.png'),
        code: 'PY',
        dial_code: '+595',
    },
    {
        name: 'Peru',
        flag: require('../assets/images/country-flags/Peru.png'),
        code: 'PE',
        dial_code: '+51',
    },
    {
        name: 'Philippines',
        flag: require('../assets/images/country-flags/Philippines.png'),
        code: 'PH',
        dial_code: '+63',
    },
    {
        name: 'Pitcairn',
        flag: require('../assets/images/country-flags/Pitcairn-Islands.png'),
        code: 'PN',
        dial_code: '+64',
    },
    {
        name: 'Poland',
        flag: require('../assets/images/country-flags/Poland.png'),
        code: 'PL',
        dial_code: '+48',
    },
    {
        name: 'Portugal',
        flag: require('../assets/images/country-flags/Portugal.png'),
        code: 'PT',
        dial_code: '+351',
    },
    {
        name: 'Puerto Rico',
        flag: require('../assets/images/country-flags/Puerto-Rico.png'),
        code: 'PR',
        dial_code: '+1939',
    },

    { name: 'q', title: true, code: 'q', dial_code: '-1' },
    {
        name: 'Qatar',
        flag: require('../assets/images/country-flags/Qatar.png'),
        code: 'QA',
        dial_code: '+974',
    },

    { name: 'r', title: true, code: 'r', dial_code: '-1' },
    {
        name: 'Romania',
        flag: require('../assets/images/country-flags/Romania.png'),
        code: 'RO',
        dial_code: '+40',
    },
    {
        name: 'Russia',
        flag: require('../assets/images/country-flags/Russia.png'),
        code: 'RU',
        dial_code: '+7',
    },
    {
        name: 'Rwanda',
        flag: require('../assets/images/country-flags/Rwanda.png'),
        code: 'RW',
        dial_code: '+250',
    },
    {
        name: 'Reunion',
        flag: require('../assets/images/country-flags/Reunion.png'),
        code: 'RE',
        dial_code: '+262',
    },

    { name: 's', title: true, code: 's', dial_code: '-1' },
    {
        name: 'Saint Barthelemy',
        flag: require('../assets/images/country-flags/Saint-Barthelemy.png'),
        code: 'BL',
        dial_code: '+590',
    },
    {
        name: 'Saint Helena',
        flag: require('../assets/images/country-flags/Saint-Helena.png'),
        code: 'SH',
        dial_code: '+290',
    },
    {
        name: 'Saint Kitts & Nevis',
        flag: require('../assets/images/country-flags/Saint-Kitts-and-Nevis.png'),
        code: 'KN',
        dial_code: '+1869',
    },
    {
        name: 'Saint Lucia',
        flag: require('../assets/images/country-flags/Saint-Lucia.png'),
        code: 'LC',
        dial_code: '+1758',
    },
    {
        name: 'Saint Martin',
        flag: require('../assets/images/country-flags/Saint-Martin.png'),
        code: 'MF',
        dial_code: '+590',
    },
    {
        name: 'Saint Pierre & Miquelon',
        flag: require('../assets/images/country-flags/France.png'),
        code: 'PM',
        dial_code: '+508',
    },
    {
        name: 'Saint Vincent & Grenadines',
        flag: require('../assets/images/country-flags/Saint-Vincent-and-the-Grenadines.png'),
        code: 'VC',
        dial_code: '+1784',
    },
    {
        name: 'Samoa',
        flag: require('../assets/images/country-flags/Samoa.png'),
        code: 'WS',
        dial_code: '+685',
    },
    {
        name: 'San Marino',
        flag: require('../assets/images/country-flags/San-Marino.png'),
        code: 'SM',
        dial_code: '+378',
    },
    {
        name: 'Sao Tome & Principe',
        flag: require('../assets/images/country-flags/Sao-Tome-and-Principe.png'),
        code: 'ST',
        dial_code: '+239',
    },
    {
        name: 'Saudi Arabia',
        flag: require('../assets/images/country-flags/Saudi-Arabia.png'),
        code: 'SA',
        dial_code: '+966',
    },
    {
        name: 'Senegal',
        flag: require('../assets/images/country-flags/Senegal.png'),
        code: 'SN',
        dial_code: '+221',
    },
    {
        name: 'Serbia',
        flag: require('../assets/images/country-flags/Serbia.png'),
        code: 'RS',
        dial_code: '+381',
    },
    {
        name: 'Seychelles',
        flag: require('../assets/images/country-flags/Seychelles.png'),
        code: 'SC',
        dial_code: '+248',
    },
    {
        name: 'Sierra Leone',
        flag: require('../assets/images/country-flags/Sierra-Leone.png'),
        code: 'SL',
        dial_code: '+232',
    },
    {
        name: 'Singapore',
        flag: require('../assets/images/country-flags/Singapore.png'),
        code: 'SG',
        dial_code: '+65',
    },
    {
        name: 'Slovakia',
        flag: require('../assets/images/country-flags/Slovakia.png'),
        code: 'SK',
        dial_code: '+421',
    },
    {
        name: 'Slovenia',
        flag: require('../assets/images/country-flags/Slovenia.png'),
        code: 'SI',
        dial_code: '+386',
    },
    {
        name: 'Solomon Islands',
        flag: require('../assets/images/country-flags/Solomon-Islands.png'),
        code: 'SB',
        dial_code: '+677',
    },
    {
        name: 'Somalia',
        flag: require('../assets/images/country-flags/Somalia.png'),
        code: 'SO',
        dial_code: '+252',
    },
    {
        name: 'South Africa',
        flag: require('../assets/images/country-flags/South-Africa.png'),
        code: 'ZA',
        dial_code: '+27',
    },
    {
        name: 'South Sudan',
        flag: require('../assets/images/country-flags/South-Sudan.png'),
        code: 'SS',
        dial_code: '+211',
    },
    {
        name: 'Spain',
        flag: require('../assets/images/country-flags/Spain.png'),
        code: 'ES',
        dial_code: '+34',
    },
    {
        name: 'Sri Lanka',
        flag: require('../assets/images/country-flags/Sri-Lanka.png'),
        code: 'LK',
        dial_code: '+94',
    },
    {
        name: 'Sudan',
        flag: require('../assets/images/country-flags/Sudan.png'),
        code: 'SD',
        dial_code: '+249',
    },
    {
        name: 'Suriname',
        flag: require('../assets/images/country-flags/Suriname.png'),
        code: 'SR',
        dial_code: '+597',
    },
    {
        name: 'Swaziland',
        flag: require('../assets/images/country-flags/Swaziland.png'),
        code: 'SZ',
        dial_code: '+268',
    },
    {
        name: 'Sweden',
        flag: require('../assets/images/country-flags/Sweden.png'),
        code: 'SE',
        dial_code: '+46',
    },
    {
        name: 'Switzerland',
        flag: require('../assets/images/country-flags/Switzerland.png'),
        code: 'CH',
        dial_code: '+41',
    },
    {
        name: 'Syria',
        flag: require('../assets/images/country-flags/Syria.png'),
        code: 'SY',
        dial_code: '+963',
    },

    { name: 't', title: true, code: 't', dial_code: '-1' },
    {
        name: 'Taiwan',
        flag: require('../assets/images/country-flags/Taiwan.png'),
        code: 'TW',
        dial_code: '+886',
    },
    {
        name: 'Tajikistan',
        flag: require('../assets/images/country-flags/Tajikistan.png'),
        code: 'TJ',
        dial_code: '+992',
    },
    {
        name: 'Tanzania',
        flag: require('../assets/images/country-flags/Tanzania.png'),
        code: 'TZ',
        dial_code: '+255',
    },
    {
        name: 'Thailand',
        flag: require('../assets/images/country-flags/Thailand.png'),
        code: 'TH',
        dial_code: '+66',
    },
    {
        name: 'Timor, East',
        flag: require('../assets/images/country-flags/East-Timor.png'),
        code: 'TL',
        dial_code: '+670',
    },
    {
        name: 'Togo',
        flag: require('../assets/images/country-flags/Togo.png'),
        code: 'TG',
        dial_code: '+228',
    },
    {
        name: 'Tokelau',
        flag: require('../assets/images/country-flags/Tokelau.png'),
        code: 'TK',
        dial_code: '+690',
    },
    {
        name: 'Tonga',
        flag: require('../assets/images/country-flags/Tonga.png'),
        code: 'TO',
        dial_code: '+676',
    },
    {
        name: 'Trinidad and Tobago',
        flag: require('../assets/images/country-flags/Trinidad-and-Tobago.png'),
        code: 'TT',
        dial_code: '+1868',
    },
    {
        name: 'Tunisia',
        flag: require('../assets/images/country-flags/Tunisia.png'),
        code: 'TN',
        dial_code: '+216',
    },
    {
        name: 'Turkey',
        flag: require('../assets/images/country-flags/Turkey.png'),
        code: 'TR',
        dial_code: '+90',
    },
    {
        name: 'Turkmenistan',
        flag: require('../assets/images/country-flags/Turkmenistan.png'),
        code: 'TM',
        dial_code: '+993',
    },
    {
        name: 'Tuvalu',
        flag: require('../assets/images/country-flags/Tuvalu.png'),
        code: 'TV',
        dial_code: '+688',
    },

    { name: 'u', title: true, code: 'u', dial_code: '-1' },
    {
        name: 'Uganda',
        flag: require('../assets/images/country-flags/Uganda.png'),
        code: 'UG',
        dial_code: '+256',
    },
    {
        name: 'Ukraine',
        flag: require('../assets/images/country-flags/Ukraine.png'),
        code: 'UA',
        dial_code: '+380',
    },
    {
        name: 'UAE',
        flag: require('../assets/images/country-flags/United-Arab-Emirates.png'),
        code: 'AE',
        dial_code: '+971',
    },
    {
        name: 'United Kingdom',
        flag: require('../assets/images/country-flags/United-Kingdom.png'),
        code: 'GB',
        dial_code: '+44',
    },
    {
        name: 'United States',
        flag: require('../assets/images/country-flags/United-States.png'),
        code: 'US',
        dial_code: '+1',
    },
    {
        name: 'Uruguay',
        flag: require('../assets/images/country-flags/Uruguay.png'),
        code: 'UY',
        dial_code: '+598',
    },
    {
        name: 'Uzbekistan',
        flag: require('../assets/images/country-flags/Uzbekistan.png'),
        code: 'UZ',
        dial_code: '+998',
    },

    { name: 'v', title: true, code: 'v', dial_code: '-1' },
    {
        name: 'Vanuatu',
        flag: require('../assets/images/country-flags/Vanuatu.png'),
        code: 'VU',
        dial_code: '+678',
    },
    {
        name: 'Venezuela',
        flag: require('../assets/images/country-flags/Venezuela.png'),
        code: 'VE',
        dial_code: '+58',
    },
    {
        name: 'Vietnam',
        flag: require('../assets/images/country-flags/Vietnam.png'),
        code: 'VN',
        dial_code: '+84',
    },
    {
        name: 'Virgin Islands, British',
        flag: require('../assets/images/country-flags/British-Virgin-Islands.png'),
        code: 'VG',
        dial_code: '+1284',
    },
    {
        name: 'Virgin Islands, US',
        flag: require('../assets/images/country-flags/US-Virgin-Islands.png'),
        code: 'VI',
        dial_code: '+1340',
    },

    { name: 'w', title: true, code: 'w', dial_code: '-1' },
    {
        name: 'Wallis and Futuna',
        flag: require('../assets/images/country-flags/Wallis-And-Futuna.png'),
        code: 'WF',
        dial_code: '+681',
    },

    { name: 'y', title: true, code: 'y', dial_code: '-1' },
    {
        name: 'Yemen',
        flag: require('../assets/images/country-flags/Yemen.png'),
        code: 'YE',
        dial_code: '+967',
    },

    { name: 'z', title: true, code: 'z', dial_code: '-1' },
    {
        name: 'Zambia',
        flag: require('../assets/images/country-flags/Zambia.png'),
        code: 'ZM',
        dial_code: '+260',
    },
    {
        name: 'Zimbabwe',
        flag: require('../assets/images/country-flags/Zimbabwe.png'),
        code: 'ZW',
        dial_code: '+263',
    },
]
