import { Dialog, Grid, TextField, useMediaQuery } from "@mui/material";
import classNames from "classnames";
import InputField from "ui-components/TextFeild";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import React, { useReducer, useState } from "react";
import { toast } from "react-toastify";
import { customFlagList } from "../../../src/utils/flagsList";
import FlagInputField from "ui-components/FlagInput";
import apiServices from "services/RequestHandler";
import { useTheme } from "@emotion/react";
import CheckIcon from "@mui/icons-material/Check";

export default function CompanyModal(props: any) {
  const { activity, handleCloseModal, buttonName } = props;
  const { classes } = props;

  const [selectedBox, setSelectedBox] = useState("Commercant");
  const theme = useTheme() as any;
  const isLarge = useMediaQuery(theme.breakpoints.down("lg"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const is720 = useMediaQuery(theme.breakpoints.down("sd"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      company_name: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_code: "+33",
      phone: "",
      about_request: "",
      errorPanels: {
        company_name: false,
        first_name: false,
        last_name: false,
        email: false,
        phone_code: false,
        phone: false,
      },
      countryList: customFlagList,
    }
  );
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSend = () => {
    try {
      const emailIsValid = emailRegex.test(state.email);

      if (
        !state?.company_name ||
        !state?.first_name ||
        !state?.last_name ||
        !state?.email ||
        !emailIsValid
        // ||
        // !state?.phone_code ||
        // !state?.phone
      ) {
        setState({
          errorPanels: {
            [!state?.company_name ? "company_name" : "test1"]: true,
            [!state?.first_name ? "first_name" : "test1"]: true,
            [!state?.last_name ? "last_name" : "test1"]: true,
            email: !state.email || !emailIsValid,
            // [!state?.phone_code ? "phone_code" : "test1"]: true,
            // [!state?.phone ? "phone" : "test1"]: true,
          },
        });
      } else {
        const data = {
          company_name: state?.company_name || "-",
          business: selectedBox || "-",
          first_name: state?.first_name || "-",
          last_name: state?.last_name || "-",
          email: state?.email || "-",
          phone_code: state?.phone ? state.phone_code || "" : "",
          phone: state?.phone || "Non fourni",
          about_request: state?.about_request || "Non fourni",
          subject: buttonName || "",
          isBusiness: false,
        };

        const response = apiServices.postFromApi(
          "helper/ppost/send-query",
          data,
          ""
        );
        toast.success("L'e-mail a été envoyé avec succès!");
        setTimeout(() => {
          handleCloseModal();
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCountryCodes = () => {
    try {
      let country_codes = state.countryList;
      let country_codes_options = country_codes?.map((x: any) => {
        return {
          label: `${x?.dial_code}`,
          value: x?.dial_code,
        };
      });
      setState({
        country_codes_options: country_codes_options,
      });
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getCountryCodes();
  }, []);

  const enable_flag_input = (state: any, value: any) => {
    try {
      setState({
        [state]: value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handle_change_mobile_code = (dialcode: any) => {
    try {
      setState({
        phone_code: dialcode?.replace("+", ""),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handle_change_mobile = (event: any) => {
    try {
      setState({
        phone: event.target.value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    setState({
      company_name: "",
      // company_address: "",
      business: null,
      first_name: "",
      last_name: "",
      email: "",
      phone_code: "+33",
      phone: "",
      about_request: "",
      errorPanels: {
        company_name: false,
        // company_address: false,
        business: false,
        first_name: false,
        last_name: false,
        email: false,
        phone_code: false,
        phone: false,
      },
    });
    setSelectedBox("Commercant");
  }, [!activity]);

  const handleSelect = (box: any) => {
    setSelectedBox(box);
  };

  const boxStyle = (isSelected: any) => ({
    height: 43,
    width: "100%",
    borderRadius: 35,
    background: isSelected ? "#f9d9b6" : "#f9d9b6",
    border: isSelected ? "2px solid #3026F1" : "1px solid #bfa78c",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 18px",
    color: isSelected ? "#3026F1" : "black",
    cursor: "pointer",
    fontFamily: "Inter-Regular",
    fontWeight: isSelected ? 600 : "",
    fontSize: "17px",
  });

  React.useEffect(() => {
    if (buttonName === "Demander d’Informations Logisticien") {
      setSelectedBox("Logisticien");
    } else {
      setSelectedBox("Commercant");
    }
  }, [buttonName]);

  return (
    <Dialog
      open={activity}
      PaperProps={{
        style: {
          overflow: "hidden",
          paddingBlock: "40px",
          minWidth: isMobile
            ? "280px"
            : is720
            ? "500px"
            : isLarge
            ? "670px  "
            : "928px",
          position: "relative",
          borderRadius: 20,
        },
      }}
      onClose={handleCloseModal}
    >
      <div
        style={{
          position: "absolute",
          top: 20,
          right: 20,
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="26px"
          height="26px"
          style={{ cursor: "pointer" }}
          onClick={handleCloseModal}
        >
          <circle cx="12" cy="12" r="10" fill="#F9D9B6" />

          <line x1="8" y1="8" x2="16" y2="16" stroke="blue" strokeWidth="1" />
          <line x1="16" y1="8" x2="8" y2="16" stroke="blue" strokeWidth="1" />
        </svg>
      </div>
      <div
        style={{
          overflow: "scroll",
          paddingInline: isMobile ? "25px" : isMedium ? 70 : 100,
        }}
      >
        <div>
          <h2
            style={{
              fontFamily: "Inter-Regular",
              fontSize: isMobile ? 24 : 36,
              fontWeight: 400,
              color: "#000000",
              whiteSpace: is720 ? "wrap" : "nowrap",
              lineHeight: 1,
            }}
          >
            Je souhaite être recontacté
          </h2>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} sm={12} lg={12} style={{ marginTop: 26 }}>
            <div style={{ fontSize: "18px", paddingLeft: "5px" }}>
              <p>Vous êtes: </p>
            </div>
          </Grid>

          <Grid item xs={12} md={6} sm={12} lg={6}>
            <div>
              <div
                style={boxStyle(selectedBox === "Commercant")}
                onClick={() => handleSelect("Commercant")}
              >
                <span>Commerçant</span>
                {selectedBox === "Commercant" && (
                  <CheckIcon style={{ color: "#3026F1" }} />
                )}
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={6} sm={12} lg={6}>
            <div
              style={boxStyle(selectedBox === "Logisticien")}
              onClick={() => handleSelect("Logisticien")}
            >
              <span>Logisticien</span>
              {selectedBox === "Logisticien" && (
                <CheckIcon style={{ color: "#3026F1" }} />
              )}
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <InputField
              fullWidth
              value={state?.company_name}
              size={"small"}
              variant={"outlined"}
              placeholder={"Nom de l’entreprise"}
              InputProps={{
                className: classNames(classes?.input_feild, "Inter-Regular"),
              }}
              inputProps={{
                className: classNames(classes?.input_feild, "Inter-Regular"),
              }}
              error={!state?.company_name && state?.errorPanels?.company_name}
              bgColor={"#F9D9B6"}
              classes={classes}
              onChange={(e: any) => {
                const { value } = e.target;
                setState({
                  company_name: value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={5}>
            <InputField
              fullWidth
              value={state?.first_name}
              size={"small"}
              variant={"outlined"}
              placeholder={"Prénom"}
              InputProps={{
                className: classNames(classes?.input_feild, "Inter-Regular"),
              }}
              inputProps={{
                className: classNames(classes?.input_feild, "Inter-Regular"),
              }}
              error={!state?.first_name && state?.errorPanels?.first_name}
              bgColor={"#F9D9B6"}
              classes={classes}
              onChange={(e: any) => {
                const { value } = e.target;
                setState({
                  first_name: value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7}>
            <InputField
              fullWidth
              value={state?.last_name}
              size={"small"}
              variant={"outlined"}
              placeholder={"Nom de famille"}
              InputProps={{
                className: classNames(classes?.input_feild, "Inter-Regular"),
              }}
              inputProps={{
                className: classNames(classes?.input_feild, "Inter-Regular"),
              }}
              error={!state?.last_name && state?.errorPanels?.last_name}
              bgColor={"#F9D9B6"}
              classes={classes}
              onChange={(e: any) => {
                const { value } = e.target;
                setState({
                  last_name: value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <InputField
              fullWidth
              value={state?.email}
              size={"small"}
              variant={"outlined"}
              placeholder={"Email"}
              InputProps={{
                className: classNames(classes?.input_feild, "Inter-Regular"),
              }}
              inputProps={{
                className: classNames(classes?.input_feild, "Inter-Regular"),
              }}
              error={state?.errorPanels?.email}
              bgColor={"#F9D9B6"}
              classes={classes}
              onChange={(e: any) => {
                const { value } = e.target;
                setState({
                  email: value,
                  errorPanels: { ...state.errorPanels, email: false },
                });
              }}
              onKeyPress={(e: any) => {
                const allowedChars = /^[a-zA-Z0-9@._-]+$/;
                if (!allowedChars.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <FlagInputField
              value={state?.phone}
              classes={classes}
              name="mobile"
              iso={"FR"}
              countryCode={state?.phone_code ?? "+33"}
              enableFlagInput={enable_flag_input}
              handleChangeMobileCode={handle_change_mobile_code}
              handleChangeMobile={handle_change_mobile}
              error={state.errorPanels?.phone && !state?.phone}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <InputField
              fullWidth
              value={state?.about_request}
              size="small"
              variant="outlined"
              placeholder="Quelques mots sur votre demande ?"
              multiline
              rows={4}
              InputProps={{
                className: classNames(classes?.input_feild, "Inter-Regular"),
              }}
              inputProps={{
                className: classNames(classes?.input_feild, "Inter-Regular"),
                style: {
                  minHeight: "100px",
                  overflow: "auto",
                  borderRadius: "10px",
                },
              }}
              border_Radius="20px"
              bgColor="#F9D9B6"
              classes={classes}
              onChange={(e: any) => {
                const { value } = e.target;
                setState({
                  about_request: value,
                });
              }}
              style={{ borderRadius: "10px" }}
            />
          </Grid>

          <Grid item xs={12} md={4} style={{ marginTop: 10 }}>
            <div
              style={{
                width: "100%",
                height: 45,
                borderRadius: "35px",
                backgroundColor: "#3026F1",
                color: "#ffffff",
                fontSize: "14px",
                cursor: "pointer",
                textTransform: "capitalize",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 20px",
                fontFamily: "Inter-Regular",
              }}
              onClick={() => {
                handleSend();
              }}
            >
              <span>Envoyer</span>
              <ArrowCircleRightIcon style={{ color: "#F9D9B6" }} />
            </div>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}
