import { useTheme } from "@emotion/react";
import { Grid, useMediaQuery } from "@mui/material";
import { useState } from "react";
import CompanyModal from "ui-components/CompanyModal";
import service_btn from "../../../assets/images/banner/1024_service_btn.png";
import service_1 from "../../../assets/images/banner/480-service-1.svg";
import service_2 from "../../../assets/images/banner/480-service-2.svg";
import service_3 from "../../../assets/images/banner/480-service-3.svg";
import service_4 from "../../../assets/images/banner/480-service-4.svg";
import mobileBanner from "../../../assets/images/banner/mobile_banner.svg";
import banner from "../../../assets/images/header/1920 Header Updated.svg";
import banner_1024 from "../../../assets/images/header/1024_Header.svg";
import banner_720 from "../../../assets/images/header/720_Header.svg";
import banner_900 from "../../../assets/images/header/900_Header.svg";
import divider from "../../../assets/images/header/divider.png";

const Banner = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [buttonName, setButtonName] = useState("");

  const theme = useTheme() as any;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLaptop = useMediaQuery(theme.breakpoints.down("lg"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sd"));

  const handleOpenModal = (name: any) => {
    setButtonName(name);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <div className="banner_container">
      <div style={{ position: "relative" }}>
        <img
          src={
            isMobile
              ? mobileBanner
              : isSmall
              ? banner_720
              : isTablet
              ? banner_900
              : isLaptop
              ? banner_1024
              : banner
          }
          alt=""
          style={{
            minWidth: "100%",
            marginTop: isMobile ? 0 : -1,
            marginLeft: isMobile ? 0 : -2,
          }}
        />

        {/* -----For MOBILE------------- */}
        {isMobile && (
          <div>
            <div className="mobile_banner" style={{}}>
              <div style={{ fontWeight: 400 }}>
                <h1 style={{ fontSize: "22px", fontWeight: 400 }}>
                  Votre allié logistique,
                </h1>
                <h1 style={{ fontSize: "22px", fontWeight: 400 }}>
                  partout, <span style={{ color: "#F9D9B6" }}>en toute</span>
                </h1>
                <h1
                  style={{
                    fontSize: "22px",
                    fontWeight: 400,
                    color: "#F9D9B6",
                  }}
                >
                  simplicité
                </h1>
              </div>
              <div style={{ fontSize: "12px", width: "75%" }}>
                <p>
                  Notre entreprise vous accompagne dans la gestion de vos
                  stocks, l’emballage et la livraison de vos produits, du
                  premier au dernier kilomètre.
                </p>
              </div>
              <div
                className="banner_btn_480"
                onClick={() => handleOpenModal(`Nous découvrir`)}
              >
                Nous découvrir
              </div>
            </div>
            <div>
              <h3
                style={{
                  fontFamily: "Inter-Regular",
                  fontSize: 36,
                  textAlign: "center",
                  fontWeight: 400,
                }}
              >
                Nos services
              </h3>
            </div>
          </div>
        )}

        {/* FOR Laptop BELOW 1024 */}
        {isLaptop && !isMobile && !isTablet && (
          <div style={{ background: "red" }}>
            <div className="tablet-heading" style={{ width: "60%" }}>
              <h2 className="tablet-para">
                Votre allié logistique, partout, en toute simplicité
              </h2>
              <p>
                Notre entreprise vous accompagne dans la gestion de vos stocks,
                l’emballage et la livraison de vos produits, du premier au dernier kilomètre.
              </p>
            </div>{" "}
          </div>
        )}

        {/* FOR Tablet BELOW 900 */}
        {isTablet && !isMobile && (
          <div className="tablet-heading" style={{ top: isSmall ? "24%" : "" }}>
            <div style={{ fontWeight: 400 }}>
              <h1 className="tablet-para">Votre allié logistique,</h1>
              <h1 className="tablet-para">
                partout, <span style={{ color: "#F9D9B6" }}>en toute</span>
              </h1>
              <h1 className="tablet-para" style={{ color: "#F9D9B6" }}>
                simplicité
              </h1>
            </div>
            <div style={{ width: "75%" }}>
              <p>
                Notre entreprise vous accompagne dans la gestion de vos stocks,
                l’emballage et la livraison de vos produits, du premier au dernier kilomètre
              </p>
            </div>
            <div
              className="banner_btn_720"
              onClick={() => handleOpenModal("Nous découvrir")}
            >
              Nous découvrir
            </div>
          </div>
        )}
        {!isMobile && !isSmall && !isTablet && isLaptop && (
          <div
            className="banner_btn_1920"
            onClick={() => handleOpenModal("Nous découvrir")}
          >
            Nous découvrir
          </div>
        )}
      </div>

      {!isMobile && !isSmall && !isTablet && !isLaptop && (
        <div
          className="banner_btn_1920"
          onClick={() => handleOpenModal("Nous découvrir")}
        >
          Nous découvrir
        </div>
      )}
      {/* Mobile sevive Buttons */}
      {isMobile && (
        <div
          style={{
            display: "flex",
            // flexDirection: "column",
            flexWrap: "wrap",
            rowGap: 5,
            marginTop: 20,
          }}
        >
          <div className="mobile_service_btn">
            <img src={service_1} alt="" />
            <h6 className="mobile_service_btn_text">
              Livraison en France et à l’international
            </h6>
          </div>
          <div className="mobile_service_btn">
            <img src={service_2} alt="" />
            <h6 className="mobile_service_btn_text">
              Préparation des colis et contrôle qualité
            </h6>
          </div>
          <div className="mobile_service_btn">
            <img src={service_3} alt="" />
            <h6 className="mobile_service_btn_text">
              Gestion des commandes et des stocks en un clic
            </h6>
          </div>
          <div className="mobile_service_btn">
            <img src={service_4} alt="" />
            <h6 className="mobile_service_btn_text">
              Stockage des produits en entrepôt
            </h6>
          </div>
        </div>
      )}
      {/* Laptop sevive Buttons */}
      {isLaptop && !isMobile && (
        <div className="hero_banne">
          <Grid
            container
            style={{
              height: "10%",
              paddingTop: isLaptop ? 20 : 40,
              width: "100%",
            }}
          >
            <Grid item xs={12} sm={1.5} md={1.5} lg={1.5}></Grid>
            <Grid item xs={12} sm={9} md={9} lg={9}>
              <div className="laptop_service_btn">
                <div>
                  <h2 className="service_heading">Nos services </h2>
                </div>
                <div>
                  <img src={service_btn} />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={1.5} md={1.5} lg={1.5}></Grid>
          </Grid>
        </div>
      )}

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!isMobile && (
          <div
            style={{
              width: isLaptop ? "86%" : "67%",
            }}
          >
            <img
              src={divider}
              style={{
                padding: "40px 50px 50px 55px",
              }}
            />
          </div>
        )}
      </div>

      <CompanyModal
        activity={modalOpen}
        handleCloseModal={handleCloseModal}
        buttonName={buttonName}
      />
    </div>
  );
};

export default Banner;
