export const defaultLabelColors = {
  color_e: "#181818",
  color_f: "#666666",
  color_g: "#eeeeee",
  color_h: "#f8f8f8",
};

export const dateRangeFilters = [
  { label: "All Time Records", value: "all_time" },
  { label: "This Year", value: "this_year" },
  { label: "This Quarter", value: "this_quarter" },
  { label: "This Month", value: "this_month" },
  { label: "This Week", value: "this_week" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Previous Week", value: "previous_week" },
  { label: "Previous Month", value: "previous_month" },
];
