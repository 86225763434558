import { useTheme } from "@emotion/react";
import { Grid, useMediaQuery } from "@mui/material";
import banner480 from "../../../assets/images/banner/partnerBanner480.svg";
import banner1024 from "../../../assets/images/banner/partnerBanner_1024.svg";
import BackgroundPeople from "../../../assets/images/header/Group16.svg";
import Partner1 from "../../../assets/images/header/Partner1.png";
import Partner2 from "../../../assets/images/header/Partner2.png";
import Partner3 from "../../../assets/images/header/Partner3.png";
import Partner4 from "../../../assets/images/header/Partner4.png";
import Partner5 from "../../../assets/images/header/Partner5.png";

const all_partners = [
  {
    path: Partner1,
    id: 1,
  },
  {
    path: Partner2,
    id: 2,
  },
  {
    path: Partner3,
    id: 3,
  },
  {
    path: Partner4,
    id: 4,
  },
  {
    path: Partner5,
    id: 5,
  },
];

function Partners(props: any) {
  const theme = useTheme() as any;
  const isLaptopDown = useMediaQuery(theme.breakpoints.down("xl"));
  const isLarge = useMediaQuery(theme.breakpoints.down("lg"));
  const is720 = useMediaQuery(theme.breakpoints.down("sd"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div style={{}}>
      <Grid container style={{ paddingTop: 50 }}>
        <Grid item xs={1} sm={is720 ? 2 : 1.5} md={1.5} lg={2.3}></Grid>
        <Grid
          className="partner_text"
          item
          xs={10}
          sm={is720 ? 8 : 9}
          md={9}
          lg={7.4}
          style={{
            marginInline: isMobile ? "30px" : "",
          }}
        >
          <h2 className="partner_heading">Nos partenaires</h2>

          <p
            className="partner_para"
            style={{
              fontSize: is720 ? 18 : "21px",
            }}
          >
            Nous collaborons avec un réseau de transporteurs spécialisés,
            sélectionnés pour répondre à vos besoins spécifiques, afin de
            garantir une gestion fluide et des livraisons sécurisées dans les
            délais impartis.
          </p>

          <div style={{ paddingTop: isMobile ? 30 : 50, width: "100%" }}>
            <div
              className="partner_logo_div"
              style={{
                height: isMobile ? "100%" : 126,
                border: is720 ? "none" : "1px solid darkgray",
              }}
            >
              <div
                className="partner_logo"
                style={{
                  justifyContent: isLaptopDown ? "center" : "space-around",
                  padding: isMobile ? 20 : isLaptopDown ? 25 : 40,
                  flexWrap: is720 ? "wrap" : "nowrap",
                }}
              >
                {all_partners?.map((x: any, index: number) => {
                  return (
                    <Grid
                      item
                      xs={isMobile ? 6 : 3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        marginBottom: isMobile ? 20 : 0,
                      }}
                      key={index}
                    >
                      <img
                        alt="partners"
                        src={x?.path}
                        style={{
                          height: isMobile ? 35 : isLaptopDown ? 37 : 49,
                          objectFit: "contain",
                        }}
                      />
                    </Grid>
                  );
                })}
              </div>
            </div>
          </div>
          {!isMobile && !isLarge && (
            <div>
              <img
                alt="backgroundPeople"
                src={BackgroundPeople}
                style={{
                  // width: "1170px",
                  objectFit: "contain",
                  marginTop: 50,
                }}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={1} sm={is720 ? 2 : 1.5} md={1.5} lg={2.3}></Grid>
      </Grid>

      {isLarge && !isMobile && (
        <div style={{ position: "relative" }}>
          <img
            alt="backgroundPeople"
            src={banner1024}
            style={{
              height: "100%",
              objectFit: "contain",
              marginTop: 40,
            }}
          />
          <div
            className="partner_banner"
            style={{
              rowGap: is720 ? 0 :isMedium ? "10px" : isMobile ? "14px" : "18px",
              fontSize: is720 ? 10: isMedium ? "12px" : isMobile ? "14px" : "",
            }}
          >
            <h2 style={{ width: "46%",  fontWeight:400 }}>
              La Solution de transport pour<br /> les E-commerçants
            </h2>
            <p>
              Notre solution logistique complète s'intègre facilement à votre
              écosystème grâce à notre API, compatible avec n'importe quel site{" "}
            </p>
            <p>
              Notre ERP vous offre un contrôle total sur vos expéditions, vos
              stocks et l'ensemble de votre chaîne d'approvisionnement, vous
              permettant de gérer et d'optimiser vos opérations en temps réel.{" "}
            </p>
          </div>
        </div>
      )}
      {isMobile && (
        <div style={{ position: "relative" }}>
          <img
            alt="backgroundPeople"
            src={banner480}
            style={{
              objectFit: "contain",
              marginTop: 40,
            }}
          />
          <div
            className="mobile_partner_banner"
            style={{
              rowGap: isSmall ? "0px" : isMobile ? "10px" : "18px",
              fontSize: isSmall ? "12px" : isMobile ? "14px" : "",
            }}
          >
            <h2 style={{ fontSize: isMobile ? "16px" : "", width: "70%" }}>
              La Solution de transport pour les E-commerçants
            </h2>
            <p>
              Notre solution logistique complète s'intègre facilement à votre
              écosystème grâce à notre API, compatible avec n'importe quel site{" "}
            </p>
            <p>
              Notre ERP vous offre un contrôle total sur vos expéditions, vos
              stocks et l'ensemble de votre chaîne d'approvisionnement, vous
              permettant de gérer et d'optimiser vos opérations en temps réel.{" "}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Partners;
