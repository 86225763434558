import { Box } from "@mui/material";
import Footer from "./Footer";
import Header from "./Header";
import Partners from "./Partners";
import Merchant from "./Merchant";
import Services from "./Services";
import Banner from "./Banner";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function MainLayout() {
  const navigation = useNavigate();
  useEffect(() => {
    if (window.location.pathname == "/") {
      navigation("/fr");
    }
  }, []);

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <Banner />
      <Header />
      <Merchant />
      <Partners />
      <Footer />
    </Box>
  );
}
