import { useTheme } from "@emotion/react";
import { Grid, useMediaQuery } from "@mui/material";
import { useState } from "react";
import CompanyModal from "ui-components/CompanyModal";
import blueBanner_1024 from "../../../assets/images/banner/1024Bluebanner-06.png";
import Chart from "../../../assets/images/banner/1920Chart.png";
import blueBanner_720 from "../../../assets/images/banner/720_blueBackgrond.svg";
import BlueBackground from "../../../assets/images/banner/Blue_banner-06.svg";
import mobileBlueBg from "../../../assets/images/banner/mobileBlue_Bg.svg";
import serviceCahrt480 from "../../../assets/images/banner/serviceChart_720.webp";

function Merchant(props: any) {
  const [modalOpen, setModalOpen] = useState(false);
  const [buttonName, setButtonName] = useState("");
  const theme = useTheme() as any;

  const isLaptop = useMediaQuery(theme.breakpoints.down("xxl"));
  const isDownLaptop = useMediaQuery(theme.breakpoints.down("xl"));
  const isLarge = useMediaQuery(theme.breakpoints.down("lg"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const is720 = useMediaQuery(theme.breakpoints.down("sd"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const handleOpenModal = (name: any) => {
    setButtonName(name);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="webscreen">
        <Grid
          container
          spacing={isMobile ? 2 : 2}
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Grid item xs={12} sm={is720 ? 12 : 1} md={1.5} lg={2.3}></Grid>
          <Grid item xs={10} sm={is720 ? 9 : 4.5} md={4.5} lg={3.7}>
            <div
              className="merchant_card"
              style={{
                height: is720 ? "200px" : isDownLaptop ? "230px" : "270px",
                padding: isDownLaptop ? 30 : 60,
                margin: isSmall ? "10px 40px" : 0,
              }}
            >
              <h2
                className="merchant_card_heading"
                style={{
                  fontSize: isDownLaptop ? 26 : isLaptop ? 32 : 36,
                  whiteSpace: isMedium ? "wrap" : "nowrap",
                  fontWeight: 400,
                }}
              >
                Vous êtes commerçant
              </h2>
              <p
                className="merchant_card_para"
                style={{
                  fontSize: isDownLaptop ? 16 : 21,
                }}
              >
                Vous souhaitez externaliser la gestion de vos commandes et la
                livraison?
              </p>

              <button
                className="merchant_card_btn"
                style={{
                  marginTop: isMobile ? 20 : isLaptop ? 60 : 30,
                  width: isLaptop ? 180 : 200,
                  bottom: is720 ? "20px" : isMedium ? "20px" : "50px",
                }}
                onClick={() =>
                  handleOpenModal("Demander d’Informations Commerçant")
                }
              >
                En savoir plus
              </button>
            </div>
          </Grid>

          <Grid item xs={10} sm={is720 ? 9 : 4.5} md={4.5} lg={3.7}>
            <div
              className="merchant_card"
              style={{
                height: is720 ? "200px" : isDownLaptop ? "230px" : "270px",
                padding: isDownLaptop ? 30 : 60,
                margin: isSmall ? "10px 40px" : 0,
              }}
            >
              <h2
                className="merchant_card_heading"
                style={{
                  fontSize: isDownLaptop ? 26 : isLaptop ? 32 : 36,
                  whiteSpace: isMedium ? "wrap" : "nowrap",
                  fontWeight: 400,
                }}
              >
                Vous êtes logisticien
              </h2>
              <p
                className="merchant_card_para"
                style={{
                  fontSize: isDownLaptop ? 16 : 21,
                }}
              >
                Vous souhaitez livrer à nos côtés?
              </p>

              <button
                className="merchant_card_btn"
                style={{
                  marginTop: isMobile ? 20 : isLaptop ? 60 : 30,
                  width: isLaptop ? 180 : 200,
                  bottom: is720 ? "20px" : isMedium ? "20px" : "50px",
                }}
                onClick={() =>
                  handleOpenModal("Demander d’Informations Logisticien")
                }
              >
                En savoir plus
              </button>
            </div>
          </Grid>
          <Grid item xs={12} sm={is720 ? 12 : 1} md={1.5} lg={2.3}></Grid>
        </Grid>

        <Grid
          container
          style={{
            backgroundImage: isMobile
              ? `url(${mobileBlueBg})`
              : is720
              ? `url(${blueBanner_720})`
              : isLarge
              ? `url(${blueBanner_1024})`
              : `url(${BlueBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: isMobile
              ? "850px"
              : is720
              ? "1100px"
              : isMedium
              ? "730px"
              : isLarge
              ? "760px"
              : isLaptop
              ? "850px"
              : "1100px",

            width: "100%",
            marginTop: isMobile ? 30 : -30,
          }}
        >
          <Grid item xs={1} sm={is720 ? 1.5 : 1.5} md={1.5} lg={2.3}></Grid>
          <Grid
            item
            xs={10}
            sm={is720 ? 9 : 9}
            md={9}
            lg={7.4}
            style={{
              padding: isMobile ? "50px 0px 10px 0pc" : "80px 0px 20px 0px",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              alignItems: "flex-start",
            }}
          >
            <div style={{ width: "100%", marginBottom: "20px" }}>
              <h1
                className="blue_banner_hrading"
                style={{
                  fontSize: isMobile ? 24 : 34,
                  fontWeight: 400,
                }}
              >
                Comment ça marche?
              </h1>
              <p className="blue_banner_para">
                Nos solutions sont créées sur mesure, selon vos besoins.
              </p>
              <p className="blue_banner_para">
                Transformez votre logistique en un véritable levier de
                croissance, optimisez chaque étape pour booster votre
                performance et satisfaire vos clients.
              </p>
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: isMobile ? "flex-start" : "center",
                  paddingTop: isMobile ? 20 : isLaptop ? 0 : 60,
                }}
              >
                <img
                  src={is720 ? serviceCahrt480 : Chart}
                  style={{
                    // height: isMobile ? "" : isLaptop ? "450px" : "586px",
                    marginTop: "50px",
                    width: isSmall ? "90%" : isMobile ? "90%" : "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={1} sm={is720 ? 1.5 : 1.5} md={1.5} lg={2.3}></Grid>
        </Grid>

        <CompanyModal
          activity={modalOpen}
          handleCloseModal={handleCloseModal}
          buttonName={buttonName}
        />
      </div>
    </>
  );
}

export default Merchant;
